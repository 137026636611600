import RecommendedArticlesSection from 'components/Homepage/RecommendedArticlesSection';
import React, { useEffect , useState} from 'react';

import { ContentWithImage,  CTA } from '../../../utils/types';

export interface RecommendedArticlesContainerProps {
  content: {
    title?: string;
    headline?: string;
    listOfContentWithImages?: ContentWithImage[];
    mainCta?: CTA;
  };
}


export const RecommendedArticlesContainer: React.FC<RecommendedArticlesContainerProps> =
  ({ content }) => {
    if (!content) {
      return null;
    }

    // necessary for SSR https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    const { title, headline, listOfContentWithImages, mainCta } = content;
    
    return (
      <RecommendedArticlesSection
        title={title}
        headline={headline}
        listOfContent={listOfContentWithImages}
        mainCta={mainCta}
      />
    );
  };

export default RecommendedArticlesContainer;

import { Container, Row, Col, Card, colors } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';

import { ContentWithImage, CTA } from '../../../utils/types';
import * as S from './styles';

export interface RecommendedArticlesSectionProps {
  title?: string;
  headline?: string;
  mainCta?: CTA;
  listOfContent?: ContentWithImage[];
}

export const RecommendedArticlesSection: React.FC<RecommendedArticlesSectionProps> =
  ({ title, headline, mainCta, listOfContent }) => (
    <>
    {listOfContent &&
      <SectionWrapper style={{ backgroundColor: colors.teal1 }}>
        <Container>
          <Row>
            <Col xs={12}>
              <S.Title>{title}</S.Title>
              <S.Headline>{headline}</S.Headline>
            </Col>
          </Row>
        </Container>
        <S.DesktopCardsContainer>
          {listOfContent.map((item, index) => (
            <S.CardWrapper key={index}>
              <Card.Article
                  image={
                    <Img
                      image={item.image.gatsbyImageData}
                      style={{ width: '100%', height: '100%' }}
                      alt={item.heading}
                    />
                  }
                  imageAlt={`slide-${item}`}
                  title={item.heading}
                  copy={item.description?.description || ''}
                  url={item.cta?.url ||  ''}
                  linkText={item.cta?.text}
                />
            </S.CardWrapper>
          ))}
        </S.DesktopCardsContainer>
        {mainCta && (
          <S.CtaContainer>
            <S.Button appearance="secondary" href={mainCta.url}>
              {mainCta.text}
            </S.Button>
          </S.CtaContainer>
        )}
      </SectionWrapper>
    }
  </>
  );

export default RecommendedArticlesSection;

import {
  Container as RawContainer,
  size,
  colors,
  H2,
  typography,
  mediaQueries,
  Col as Column,
  Button as BaseButton,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const Title = styled(H2)`
  text-align: center;
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xs1}px;
  }
`;
export const Headline = styled.p`
  display: none;

  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    ${typography.bodyTextSmall}
    text-align: center;
    margin-bottom: 60px;
  }
`;

export const CardWrapper = styled.div`
  margin: 12px;
  text-decoration: none;
  position: relative;

  /* keep the cards the same height on desktop TODL: make this better */
  & > a {
    height: 100%;
    min-width: 330px;
  }
`;

export const DesktopCardsContainer = styled(RawContainer)`
  display: flex;
  justify-content: start;
  overflow-x: scroll;
  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: center;
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${size.xl1}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: ${size.xl4}px;
  }
`;

export const Button = styled(BaseButton)`
  border-color: ${colors.green5};
  color: ${colors.green5};
  &:hover,
  &:focus {
    background-color: ${colors.green5};
  }
`;

export const Col = styled(Column)`
  display: flex;
  justify-content: center;
`;
